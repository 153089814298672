body table,
.dark-theme table {
    background-color: $color-white;
    border: 1px solid $color-6;
    border-collapse: separate;
    border-radius: 8px;
    width: 100%;
    thead {
        &:first-child {
            tr {
                &:first-child {
                    th,
                    td {
                        border-top: none;
                    }
                }
            }
        }
    }

    thead,
    tbody {
        tr {
            th,
            td {
                &:first-child {
                    border-left: none;
                }
                &:last-child {
                    border-right: 0;
                }
            }
        }
    }

    th {
        border-top: none;
        border-bottom: 3px solid $color-6;
        color: $color-2;
        font-size: 2.2rem;
        font-weight: bold;
        display: none;
        padding: 20px 10px;
        text-align: left;

        @include media($min: $size-lg-2){
            display: table-cell;
        }
    }

    tbody{
        .status{
            span{ 
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                margin: 0;
                overflow: hidden;
                text-indent: -300%;
                width: 38px;
                height: 38px;
                position: relative;

                @include before{
                    @extend %pseudo-block;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                }

                @include media($min: $size-lg-2){
                    margin: 0 auto;
                }
            }
        }

        .status{
            &-incomplete{
                .status{
                    span{
                        background-image: url(/assets/img/icon-cross.svg);
                    }
                }
            }

            &-pending{
                //temp icon
                .status{
                    span{
                        @include before-after{
                            @extend %pseudo-block;
                            width: 100%;
                            height: 100%;
                        }

                        @include before{
                            background-color: $color-secondary;
                        }

                        @include after{
                            color: $color-white;
                            content: "\e95a";
                            font-family: $font-icon;
                            top: 0;
                            left: 0;
                            text-indent: 0;
                            text-align: center;
                            margin-top: 8px;
                        }
                    }
                }
            }

            &-sent{
                //temp icon
                .status{
                    span{
                        @include before-after{
                            @extend %pseudo-block;
                            width: 100%;
                            height: 100%;
                        }

                        @include before{
                            background-color: $color-6;
                        }

                        @include after{
                            color: $color-white;
                            content: "\e9ca";
                            font-family: $font-icon;
                            top: 0;
                            left: 0;
                            text-indent: 0;
                            text-align: center;
                            margin-top: 9px;
                        }
                    }
                }
            }

            &-complete{
                .status{
                    span{
                        background-image: url(/assets/img/icon-check.svg);
                    }
                }
            }
        }

        tr{
            border-bottom: 2px solid $color-6;
            display: flex;
            flex-wrap: wrap;

            @include media($min: $size-lg-2){
                display: table-row;
            }

            &:last-child{
                td{
                    border-bottom: none;
                }
            }
        }

        td{
            border: none;
            border: 1px dotted $color-6;
            color: $color-2;
            flex: 1 1 100%;
            display: block;
            font-size: 1.6rem;

            @include before{
                display: block;
                font-weight: bold;
            }

            @for $i from 1 through 9 {
                &[data-heading-#{$i}]{
                    @include before{
                        content: attr(data-heading-#{$i});
                    }
                }
            }

            @include media($min: $size-lg-2){
                border: 1px solid $color-6;
                display: table-cell;
                font-size: 1.8rem;
                padding: 30px 10px;

                @include before{
                    display: none;
                }
            }

            a{
                &, &:not([href]){
                    color: $color-primary;
                    display: inline-block;
                    margin-top: 10px;
                    margin-right: 10px;
                    text-decoration: underline;

                    @include media($min: $size-lg-2){
                        display: block;
                        margin-right: 0;
                    }
                }

                &:first-child{
                    margin-top: 0;
                }
            }

            &:last-child{
                //border-bottom: 2px solid $color-6;
                vertical-align: top;

                @include media($min: $size-lg-2){
                    border-width: 1px;
                }
            }
        }

        .form-field{
            margin-bottom: 0;
        }

        p-button{
            padding: 0;
        }

        .btn-txt{
            display: inline-block;
            margin-top: 10px;
            margin-right: 10px;
            margin-left: 0;

            .p-button,
            button{
                background-color: transparent;
                border: none;
                color: $color-primary;
                padding: 0;
                text-decoration: underline;

                &, .p-button-label{
                    font-size: 1.8rem;
                }

                &:hover{
                    background-color: transparent;
                }
            }

            @include media($min: $size-lg-2){
                display: block;
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        textarea,
        .field-placeholder,
        input.p-inputtext{
            border-color: $color-6 !important;
            color: $color-2;
            font-size: 1.8rem;

            @include placeholder{
                color: $color-2;
                font-size: 1.8rem;
            }

            &[readonly=""],
            &.read-only{
                background-color: transparent;
                padding: 0;
            }

            + app-validation-error{
                .validation-error{
                    background-color: $color-white;
                    color: $color-black;
                    font-size: 1.4rem;
                    position: absolute;
                    z-index: 1;
                }
            }
        }
        
        .archive,
        .close,
        .details,
        .edit,
        .info,
        .manage,
        .btn-details button,
        .btn-details .p-button,
        .btn-cancel button,
        .btn-cancel .p-button,
        .btn-copy button,
        .btn-copy .p-button,
        .btn-edit button,
        .btn-edit .p-button,
        .btn-save button,
        .btn-save .p-button,
        .btn-remove button,
        .btn-remove .p-button{
            background-position: left center;
            background-size: 28px auto;
            display: block;
            font-size: 1.8rem;
            min-height: 28px;
            padding-left: 20px;
            position: relative;
            text-align: left;
            transition: color 0.25s ease;
            cursor: pointer;

            @include before{
                font-family: $font-icon;
                position: absolute;
                left: 0;
            }

            &:hover{
                color: $color-secondary!important;
            }
        }

        .archive{
            padding: 0 0 0 32px;
        }

        .btn-edit button,
        .icon-edit{
            background: none;
            padding-left: 20px;
            position: relative;

            @include before-after{
                @extend %pseudo-block;
                background-image: url(/assets/img/icon-edit-colored.svg);
                background-size: contain;
                width: 13px;
                height: 13px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: opacity 0.25s ease;
            }

            @include after{
                background-image: url(/assets/img/icon-edit-yellow.svg);
                opacity: 0;
            }

            &:hover{
                @include before{
                    opacity: 0;
                }

                @include after{
                    opacity: 1;
                }
            }
        }

        .btn-details button,
        .details{
            @include before{
                content: "\e966";
            }
        }

        .info{
            @include before{
                content: "\e924";
                font-size: 1.6rem;
            }
        }

        .btn-cancel button,
        .close{
            @include before{
                content: "\e90b";
            }
        }

        .btn-copy button{
            @include before{
                content: "\e957";
            }
        }

        .btn-save button{
            @include before{
                content: "\e95b";
            }
        }

        .btn-remove button{
            @include before{
                content: "\e93d";
                font-size: 1.6rem;
            }
        }
    }

    p-inputnumber{
        .p-inputtext{
            .dark-theme &{
                border: 1px solid $color-primary!important;
                color: $color-2;
                border-radius: 6px 0 0 6px!important;
            }
        }
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon{
        color: $color-2;

        @include before{
            background-color: $color-2;
        }
    }

    &.p-datepicker-calendar{
        tbody{
            tr{
                border-bottom: none;
                flex-wrap: nowrap;
            }
        }
    }
}
