.layout-header {
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .logo {
        width: 220px;
        height: 50px;
    }

    .top-nav {
        background-color: rgba($color-black, 0.9);
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*display: block; 
        float: right;*/

        .inner{
            display: flex;
            align-items: center;
            position: absolute;
            overflow-y: auto;
            width: 100%;
            height: 100%;
        }

        ul {
            list-style-type: none; 
            margin: 1rem 0 0; 
            padding: 0; 

            li {
                margin: 0 0 20px 0;
                padding: 0.5rem 4.5rem;
                
                &:last-child {
                    padding-right: 0;
                }

                a {
                    color: $color-white;
                    display: block;
                    font-size: 2.4rem;
                    margin: 0;
                    padding: 0;

                    &:hover {
                        color: $color-primary;
                        text-decoration: none;
                    }
                }

                .button-style{
                    &.primary{
                        color: $color-white;
                        padding: 10px 30px;
                    }
                }

                &.profile{
                    > a{
                        color: $color-primary;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.burger-box{
    position: fixed;
    display: block;
    width: 40px;
    height: 24px;
    top: 45px;
    right: 30px;
    z-index: 101;

    .burger-inner{
        &, &::before, &::after{
            position: absolute;
            width: 40px;
            height: 4px;
            transition: transform .75ms cubic-bezier(.55,.055,.675,.19);
            border-radius: 4px;
            background-color: $color-primary;
        }

        @include before-after{
            @extend %pseudo-block;
        }

        @include before{
            top: -10px;
            transition: top 75ms ease .12s,opacity 75ms ease;
        }

        @include after{
            bottom: -10px;
            transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55,.055,.675,.19);
        }

        button{
            background: transparent;
            border: none;
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 30px;
            top: -13px;
            left: 0;
        }
    }

    &.mobile-nav-active{
        + .top-nav{
            display: block;
            z-index: 100;
        }

        .burger-inner{
            transition-delay: .12s;
            transition-timing-function: cubic-bezier(.215,.61,.355,1);
            transform: rotate(45deg);

            @include before{
                top: 0;
                transition: top 75ms ease,opacity 75ms ease .12s;
                opacity: 0;
            }
    
            @include after{
                bottom: 0;
                transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
                transform: rotate(-90deg);
            }
        }
    }
}

@include media($min: $size-lg){
    .burger-box{
        display: none;
    }

    .layout-header {
        .wrapper{
            min-height: 150px;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .logo{
            width: 300px;
            height: 70px;
        }

        .top-nav {
            background-color: transparent;
            display: block; 
            float: right;
            position: static;
            top: auto;
            left: auto;
            width: auto;
            height: auto;

            .inner{
                display: block;
                position: static;
                width: auto;
                height: auto;
            }

            ul {
                display: flex;
                align-items: center;

                li {
                    margin: 0;
                    padding: 0.5rem 20px;

                    a {
                        color: $color-3;
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}

@include media($min: 1300px){
    .layout-header {
        .top-nav {
            ul {
                li {
                    padding: 0.5rem 45px;
                    a {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}