.dark-theme{
    background-color: $color-background;
    padding: 50px 0;

    @include media($min: $tablet){
        padding: 50px;
    }

    .wrapper &{
        overflow: visible;
    }

    &.transparent{
        background-color: transparent;
    }

    fieldset{
        border: none;
        padding: 0;

        legend{
            color: $color-white;
            font-size: 2.2rem;
            font-weight: bold;
            margin-bottom: 40px;
        }
    }

    .form-heading{
        color: $color-white;
        font-size: 2rem;
        font-weight: bold;
        margin: 1em 0;

        @include media($min: $tablet){
            font-size: 3.2rem;
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    textarea,
    .field-placeholder,
    input.p-inputtext{
        background-color: transparent;
        border: 1px solid $color-white !important;
        border-radius: 6px !important;
        color: $color-white;
        font-size: 1.4rem;
        min-height: 52px;
        padding: 10px 20px;
        width: 100%;

        @include placeholder{
            color: $color-white;
        }

        &[readonly=""],
        &.read-only{
            background-color: $color-primary;
            border: none!important;
        }

        #email-edit-page &,
        .light-input &{
            color: $color-2;
            background-color: $color-white !important;
            font-size: 21px ;
        }
    }

    input[type="number"]{
        font-size: 2rem;
    }

    .custom-checkbox{
        input[type="checkbox"]{
            display: none;

            + label{
                cursor: pointer;
                padding-right: 35px;
                position: relative;

                @include before{
                    @extend %pseudo-block;
                    background-color: $color-white;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    top: 7px;
                    right: 7px;
                    opacity: 0;
                    transition: opacity 0.25s ease;
                }

                @include after{
                    @extend %pseudo-block;
                    border: 1px solid $color-5;
                    border-radius: 5px;
                    width: 24px;
                    height: 24px;
                    top: 0;
                    right: 0;
                }
            }

            &:checked{
                + label{
                    @include before{
                        opacity: 1;
                    }
                }
            }
        }
    }

    .field-placeholder{
        display: block;
        line-height: 2.1em;
    }

    label{
        color: $color-white;
        display: inline-block;
        font-size: 2rem;
        margin: 0 0 5px 0;
    }

    .form-field{
        margin-bottom: 20px;
    }

    .has-app-notice{
        margin-top: 20px;
    }

    .app-notice{
        background: transparent;
        border: 5px solid $color-secondary;
        border-radius: 8px;
        color: $color-white;
        line-height: 1.4em;
        margin: 0 20px;
        padding: 20px;
    }

    .localization-toggle{
        margin-bottom: 20px;

        ul li.ng-star-inserted button.p-button{
            line-height: 20px;
        }
    }

    .box-wrap{
        border: 3px solid $color-secondary;
        border-radius: 6px;
        font-size: 2rem;
        padding: 30px;

        .form-field{
            @include media($min: 1400px){
                display: flex;
                font-weight: bold;
                align-items: center;
                justify-content: space-between;
            }

            label{
                display: block;
                font-size: 1.6rem;
                font-weight: bold;
                margin-bottom: 10px;

                @include media($min: 1400px){
                    font-size: 2rem;
                    font-weight: normal;
                    margin-bottom: 0;
                    padding-right: 20px;
                }
            }
        }
    }

    .distribution-participant-list{
        border-top: 4px solid $color-white;
        border-radius: 0;
        margin-top: 50px;
        padding-top: 50px;

        > fieldset{
            > legend{
                margin-bottom: 0;
            }
        }

    }

    &.inline-label{
        .field{
            display: flex;
        }
    }

    .user-details-form{
        fieldset > p-button{
            display: inline-block;
        }
    }

    .reminder-details-form{
        padding: 0 20px;
    }

    /*.license-code{width:350px;}*/
}

div.dark-theme{
    //padding-right: 20px;
    //padding-left: 20px;

    @include media($min: $tablet){
        padding-right: 20px;
        padding-left: 20px;
    }

    form.dark-theme{
        margin: 0 2px;
        padding: 20px 0;

        @include media($min: $tablet){
            padding: 20px 0.5rem;
        }
    }
}