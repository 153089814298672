%pseudo-block{
    content: "";
    display: block;
    position: absolute;
}

%listbox{
    list-style: none;
    margin: 0;
    padding: 0; 
}

%button-style{
    background-color: $color-1;
    border-radius: 6px;
    color: $color-black;
    display: inline-block;
    font-size: 2rem;
    font-weight: normal;
    padding: 10px 30px;
    text-align: center;
    transition: background 0.25s ease, color 0.25s ease;

    &:hover{
        background-color: $color-secondary;
        color: $color-black;
    }

    &.primary{
        background: $color-primary; 
        border-color: $color-primary;
        color: $color-white;

        &:hover{
            background: $color-secondary;
            border-color: $color-secondary;
            color: $color-white;
        }
    }

    &.alternate{
        border: 2px solid transparent;
        background-color: $color-secondary;

        &:hover{
            background-color: transparent;
            border-color: $color-secondary;
            color: $color-secondary;
        }
    }
}