//Dashboard
.dashboard-navigation-wrapper{
    .menu-toggle{
        color: $color-white;
        display: inline-block;
        padding: 20px;
        position: relative;

        button{
            @extend %button-style;
            @extend .alternate;
            border: none;
            color: $color-white;
            cursor: pointer;
            font-size: 1.4rem;
            /** Padding altered to accommodate the 'x' close inside the button */
            padding-left:4rem;

            &:hover,
            &:active{
                background-color: $color-secondary;
                color: $color-white;
            }
        }

        /**
        Style the 'x' close inside the button
        Hidden by default
        Visible when menu active
        */
        span {
          display: inline-block;
          color: $color-white;
          visibility: hidden;
          pointer-events: none;
        }

        &.tab-menu-active span {
          visibility: visible;
        }


      &.tab-menu-active{
            /*@include before{
                @extend %pseudo-block;
                content: "X";
                font-size: 1.3rem;
                top: 26px;
                right: 30px;
            }*/

            + .nav-tabs{
                transform: translateX(0);
            }
        }
    }

    .nav-tabs{
        @extend %listbox;
        background-color: $color-white;
        border-radius: 6px;
        padding: 20px;
        position: absolute;
        -webkit-box-shadow: 0px 0px 10px 0px rgba($color-4, 0.7);
        -moz-box-shadow: 0px 0px 10px 0px rgba($color-4, 0.7);
        box-shadow: 0px 0px 10px 0px rgba($color-4, 0.7);
        transform: translateX(-120%);
        // transition: transform 0.25s ease;
        z-index: 1;

        .dropdown-menu {
            @extend %listbox;
            background-color: $color-white;
            border-radius: 6px;
            display: none;
            position: absolute;
            padding: 20px;
            top: calc(100% + 10px);
            left: 0;
            width: 100%;
            -webkit-box-shadow: 0px 0px 10px 0px rgba($color-4, 0.7);
            -moz-box-shadow: 0px 0px 10px 0px rgba($color-4, 0.7);
            box-shadow: 0px 0px 10px 0px rgba($color-4, 0.7);
            z-index: 1;

            @include before{
                @extend %pseudo-block;
                width: 100%;
                height: 30px;
                top: -20px;
                left: 0;
            }

            @include media($min: $tablet){
                transform: translateX(70%);
                min-width: 360px;
            }

            .dropdown-item{
                a{
                    border-radius: 6px;
                    color: $color-primary;
                    display: block;
                    font-size: 1.6rem;
                    line-height: 1.3em;
                    padding: 10px 20px;
                    transition: background-color 0.25s ease;

                    &:hover{
                        color: $color-white;
                        background-color: $color-secondary;
                    }
                }
            }
        }
    }

    .nav-item {
        &.dropdown{
            position: relative;

            &:hover{
                .dropdown-menu{
                    display: block;
                }
            }
        }

        > a.nav-link {
            &,
            &:not([href]) {
                border-radius: 6px;
                color: $color-primary;
                display: block;
                font-weight: 500;
                border: 0;
                padding: 10px 20px;
                transition: background-color 0.25s ease;

                &:hover {
                    border: 0;
                    background-color: transparent;
                }

                &.activebutton,
                &.activebutton:hover,
                &:focus{
                    background-color: $color-secondary;
                    color: $color-white;
                }
            }
        }

        &:hover{
            > a.nav-link {
                &,
                &:not([href]) {
                    background-color: $color-secondary;
                    color: $color-white;
                }
            }
        }

        > a.nav-link:not([href]) {
            cursor: default;
        }
    }
}

@include media($min: $tablet){
    .dashboard-navigation-wrapper{
        background-color: $color-white;
        margin-bottom: 40px;
        position: relative;

        .menu-toggle{
            display: none;
        }

        .nav-tabs{
            @extend %listbox;
            background-color: transparent;
            border-radius: 0;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            position: relative;
            transform: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            z-index: 1000;

            .dropdown-menu {
                background-color: $color-background;
                top: 100%;
                left: auto;
                transform: translateY(15px);
                z-index: 1;

                .dropdown-item{
                    a{
                        color: $color-white;
                    }
                }
            }
        }

        .nav-item {
            background-color: transparent;
            min-height: 38px;

            > a.nav-link {
                &,
                &:not([href]) {
                    border-radius: 16px 16px 0 0;
                    color: $color-primary;
                    font-size: 1.4rem;
                    font-weight: 500;
                    margin: 0 0.2vw;
                    padding: 15px 2vw 20px;

                    &.activebutton,
                    &.activebutton:hover,
                    &:focus{
                        background-color: $color-primary;
                        color: $color-white;
                    }
                }
            }

            &:hover{
                > a.nav-link{
                    &,
                    &:not([href]) {
                        background-color: $color-primary;
                        color: $color-white;
                    }
                }
            }
        }
    }
}

@include media($min: $tablet-landscape){
    .dashboard-navigation-wrapper{
        .nav-item {
            > a.nav-link {
                &,
                &:not([href]) {
                    font-size: 2rem;
                }
            }
        }
    }
}
