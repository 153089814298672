$font-sans-serif: 'Inter', sans-serif;
$font-icon: 'primeicons';

$color-primary: #4E6B81;
$color-secondary: #FAAF34;
$color-white: #FFFFFF;
$color-black: #000000;
$color-background: #496377;

$color-1: #C5C5C5;
$color-2: #4D4D4D;
$color-3: #707070;
$color-4: #354D60;
$color-5: #CED4D8;
$color-6: #C8C8C8;
$color-7: #42647E;
$color-8: #BCCAD5;
$color-9: #EAEAEA;

$tablet: 768px;
$tablet-landscape: 1024px;
$ipad: 810px;
$size-sm: 668px;
$size-lg: 991px;
$size-lg-2: 1250px;
