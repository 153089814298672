.p-component{
    font-family: $font-sans-serif;
}

.p-element{
    &.current{
        button{
            background-color: $color-secondary;
        }
    }

    &.read-only {
        border-color: transparent !important;
        box-shadow: none !important;
        cursor: default;
    }
}

p-button{
    .wrapper &{
        border: none;
        padding: 0;
        text-align: left;

        &:hover{
            background: none;
            border: none;
        }

        &[ng-reflect-disabled="true"]{
            pointer-events: none;
        }
    }
}

p-radiobutton{
    cursor: pointer;
    margin-bottom: 15px; 
    
    label{
        cursor: pointer;
    }

    .p-radiobuttton-box{
        &.p-highlight{ 
            background-color: $color-secondary;
            border-color: $color-secondary;
        }
    }
}

p-dialog{
    .p-dialog-mask .p-dialog{
        @include media($max: 520px){
            width: 100%!important;
            max-width: 80%;
        }
    }

    .p-element{
        font-size: 1.6rem;
    }

    .p-dialog-header{
        font-size: 1.8rem;
        font-weight: bold;

        .p-dialog-header-icons{
            position: absolute;
            top: 5px;
            right: 5px;
            
            .p-dialog-header-icon{
                width: auto;
                height: auto;
                padding: 5px;
            }

            .p-dialog-header-close-icon{
                font-size: 2rem;
            }
        }
    }

    .p-dialog-content{
        p{
            line-height: 1.4em;
        }

        a{
            color: #4E6B81;
            text-decoration: underline;
        }
    } 
}

.p-dropdown-items,
p-dropdown{
    .p-dropdown-label{
        font-size: 1.8rem;
        padding-left: 20px;
    }

    p-dropdownitem,
    .p-dropdown-item{
        font-size: 1.4rem;

        &.p-highlight{
            .p-dropdown-panel &{
                background-color: $color-secondary;
                color: $color-white;
            }
        }
    }
}

p-confirmdialog{
    .p-element{
        font-size: 1.6rem;
    }

    .p-dialog {
        @include media($max: $size-sm){
            width: 100%!important;
            margin-right: 20px;
            margin-left: 20px;
            max-width: 480px;
        }

        .p-dialog-header{
            font-size: 1.8rem;
            font-weight: bold;

            .p-dialog-title{
                font-size: 1.8rem;
                font-weight: bold;
            }

            .p-dialog-header-icons{
                .p-dialog-header-icon{
                    width: auto;
                    height: auto;
                    padding: 5px;
                }

                .p-dialog-header-close{
                    font-size: 2rem;
                }
            }
        }

        .p-dialog-content{
            font-size: 1.6rem;
        }
    }
}

p-password{
    &#password.p-inputwrapper-focus{
        ~ .ng-invalid{
            .validation-error{
                margin-top: 80px;
                position: relative;
                z-index: 1;
            }
        }
    }

    ~ .ng-invalid{
        .validation-error{
            background-color: rgba($color-4, 0.9);
            position: relative;
            z-index: 1;
        }
    }

    &.p-password-mask{
        position: relative;

        .p-inputtext{
            padding-right: 30px;
        }

        .p-input-icon-right > i:last-of-type,
        i{
            color: $color-white;
            cursor: pointer;
            font-size: 2rem;
            margin-top: 0;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity 0.25s ease;

            &:hover{
                color: $color-secondary;
            }
        }

        &.p-inputwrapper-filled,
        &.p-inputwrapper-focus{
            .p-input-icon-right > i:last-of-type,
            i{
                opacity: 1;
            }
        }
    }
}

.p-toast{
    .p-toast-message.p-toast-message-info{
        background-color: $color-white;
        border: 3px solid $color-secondary;

        .p-toast-message-content{
            color: $color-black;
            font-size: 1.6rem;
            line-height: 1.2em;
            padding: 12px 30px 12px 15px;

            .p-toast-message-icon{
                color: $color-primary;
            }

            .p-toast-summary{
                font-size: 1.6rem;
                line-height: 1.2em;
            }

            .p-toast-detail{
                font-size: 1.6rem;
                line-height: 1.2em;
                margin: 0;
            }

            .p-toast-icon-close{
                color: $color-black;
                line-height: 1;
                padding: 0;
                position: absolute;
                right: 6px;

                &:hover{
                    color: $color-secondary;
                }

                .pi{
                    @include before{
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
}

.p-datepicker{
    body &{
        button{
            padding: 0;
        }

        .p-datepicker-header .p-datepicker-title{
            button.p-datepicker-year,
            button.p-datepicker-month{
                background: transparent;
                border: 1px solid $color-4;
                padding: 0 10px;

                &:hover{
                    &, &:enabled{
                        border-color: $color-secondary;
                        color: $color-secondary;
                    }
                }
            }
        }

        table td > span.p-highlight{
            background-color: $color-secondary;
            color: $color-black;
        }
    }
}

//Questionnaire related
.completion{
    p-button{
        button{
            margin-top: 30px
        }
    }
}

//form dark
.dark-theme{
    input.p-inputtext{
        background-color: transparent;
        border: 1px solid $color-white;
        border-radius: 6px;
        color: $color-white;
        font-size: 1.4rem;
        min-height: 52px;
        padding: 10px 20px;
        width: 100%;

        @include placeholder{
            color: $color-white;
            font-size: inherit;
            opacity: 1;
        }
    }

    p-inputnumber{
        input.p-inputtext{
            border-radius: 6px 0 0 6px!important;
        }
    }

    //workaround for prime elements inside sections
    .font-size-18{
        input.p-inputtext{
            font-size: 1.8rem;
        }
    }

    p-dropdown{
        flex: 1 1 100%;

        div{
            background-color: transparent;
        }

        .p-dropdown{
            border: 1px solid $color-white;
            border-radius: 5px;
            display: block;
        }

        .p-dropdown-trigger{
            width: 24px;
            height: 14px;
            line-height: 0;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);

            .p-dropdown-trigger-icon{
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 13px 12px 0 12px;
                border-color: $color-white transparent transparent transparent;

                @include before{
                    display: none;
                }
            }
        }

        .p-dropdown-items{
            background-color: $color-white;
        }

        .p-dropdown-label{
            body .layout-wrapper &{
                width: 100%;
                padding-right: 37px;
            }

            &, &.p-placeholder{
                color: $color-white;
                font-size: 1.4rem;
                line-height: 2em;
                padding: 10px 20px;
            }
        }

        p-dropdownitem{
            &, span{                
                font-size: 1.4rem;
            }
        }
    }

    p-button{
        padding: 20px 0;
        border: none;
        display: block;
        background: transparent !important;

        .p-button{
            @extend %button-style;
            @extend .alternate;

            .p-button-label{
                font-weight: inherit;
            }
        }

        button.btn-review-inactive{
            background: transparent;
            border-color: $color-secondary;
            color: $color-secondary;
        }
    }

    p-checkbox{
        margin: 0 5px;

        .p-checkbox-label{
            cursor: pointer;
            margin: 0 0 0 10px;
        }

        .p-checkbox-box{
            background-color: transparent;
            border: 1px solid $color-5;
            border-radius: 5px;
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
            position: relative;

            .p-checkbox-icon{
                @include before{
                    @extend %pseudo-block;
                    background-color: $color-white;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: opacity 0.25s ease;
                }
            }
        }

        .p-checkbox-checked{
            .p-checkbox-box{
                &.p-highlight{
                    background-color: transparent;
                    border-color: $color-5;

                    &:hover{
                        background-color: transparent;
                        border-color: $color-secondary;
                    }
                }

                .p-checkbox-icon{
                    @include before{
                        opacity: 1;
                    }
                }
            }
        }

        ~ .app-notice{
            margin-top: 10px;
            margin-left: 0;
        }
    }

    p-password{
        .p-password{
            display: flex;
        }
    }

    .form-input-error{
        color: $color-white;
    }
}
