.wrapper{
  h1{
    .layout-wrapper &{
      font-size: 4.2rem;
    }
  }
}

.icon-edit{
    background: url(/assets/img/icon-edit.svg) no-repeat;
}

.icon-archive {
  background: url(/assets/img/icon-cross.svg) no-repeat;
}

.editor{
    color: $color-black;
}

.buttons{
  p-button{
    &, .dark-theme &{
      display: inline-block;
    }
  }
}

.forgot-password-form,
.reset-password-form,
.recover-assessment-link-form{
  &.dark-theme{
    padding: 50px 0 0 0;

    .form-field{
      max-width: 350px;
      width: 100%; 
    }
  }
}

.login-form.dark-theme{
  max-width: 350px;
  padding: 50px 0 0 0;

  a{
    color: $color-secondary;
    float: right;
    font-size: 1.5rem;
    margin-top: 10px;

    &:hover{
      text-decoration: underline;
    }
  }
}

.info-boxes,
.distribution-review{ 
  @extend %listbox;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;

  @include media($min: $tablet-landscape){
    flex-wrap: nowrap;
  }

  > li{
    border: 1px solid $color-secondary;
    border-radius: 8px;
    flex: 1 1 auto;
    line-height: 1.4em;
    padding: 20px;

    @include media($min: $size-sm){
      flex: 0 1 calc(50% - 10px);
    }

    @include media($min: $tablet-landscape){
      flex: 1 1 auto;
    }
  }

  .heading{
    display: block;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.4em;
  }
}

app-pagination{
  p-button{
    .p-button{
      @include media($max: $tablet){
        margin: 0 5px;
        padding: 10px;
      }
    }
  }
}