.light-theme{
    background-color: $color-white;

    fieldset{
        background-color: transparent;
        border: none;
        border-radius: 15px;        

        -webkit-box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
        -moz-box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
        box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
        margin: 10px 0 30px 0;
        padding: 40px;
    } 

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    textarea{
        background-color: transparent;
        border: 1px solid $color-3!important;
        border-radius: 6px;
        color: $color-4;
        font-size: 2.2rem;
        opacity: 1;
        padding: 15px 20px;
        width: 100%;

        @include placeholder{
            color: $color-3;
            opacity: 0.2;
        }

        @include placeholder{
            color: $color-white;
        }

        &[readonly=""],
        &.read-only{
            background-color: $color-9;
            border: none!important;
        }
    }

    .validation-error,
    .form-input-error{
        border-width: 2px;
        margin: 5px 0;
    }
}