.participant-page{
    .participant-login{
        border-radius: 15px;
        max-width: 500px;
        text-align: center;
        margin: 60px auto;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px;
        -webkit-box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
        -moz-box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
        box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
    }

    .welcome-instructions{
        margin-top: 0;
        margin-bottom: 20px;
    }

    .introduction{
        font-size: 1.8rem;
        line-height: 1.4em;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 30px;
        max-width: 1000px;

        > div{
            > h2{
                margin-bottom: 20px;

                > p:first-child{
                    font-size: 2.2rem;
                    line-height: 1.4em;
                    margin-bottom: 30px;
                }
            }
        }

        .text-big{
            font-size: 3.2rem;
            font-weight: bold;
        }

        p{
            margin-bottom: 20px;
        }

        .validation-instructions,
        i strong,
        .note{            
            border-radius: 6px;
            display: block;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 500;
            padding: 20px;
            position: relative;
        }

        i strong,
        .note{
            background-color: rgba($color-secondary, 0.03);
            border: 1px solid $color-secondary;
            margin: 50px 0;
            padding-left: 65px;

            @include before{
                content: "\e924";
                color: $color-secondary;
                font-family: $font-icon;
                font-size: 3rem;
                position: absolute;
                top: 20px;
                left: 20px;
            }
        }

        .validation-instructions{
            border-radius: 15px;
            margin: 0 auto 60px auto;
            max-width: 800px;
            padding: 40px;
            -webkit-box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
            -moz-box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
            box-shadow: 0 0 20px 0px rgba($color-4, 0.16);
        }
    }

    .light-theme.participant-details{
        margin: 0 auto 50px auto;
        max-width: 800px;

        .participant-fields{
            margin-bottom: 30px;
        }

        .field > label{
            font-size: 1.8rem;
        }
    } 

    .description{
        margin-bottom: 30px;
    }

    .completion{
        margin: 80px 0;

        .pdf-img{
            background: url(/assets/img/pdf-img.jpg) 30px top no-repeat;
            background-size: contain;
            overflow: hidden;
            padding-top: calc((612/693) * 100%);
            height: 0;
        }

        h2{
            margin-bottom: 30px;
            font-size: 3.6rem;
        }
    }

    .grid.token{
        padding-top: 30px;

        .col-12{
            margin-right: auto;
            margin-left: auto;
            max-width: 1140px;
        }

        a.button-style{
            margin-top: 20px;
        }
    }
}