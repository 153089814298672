.stats{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin: 0;
    
    > div{
        background-color: $color-white;
        border-radius: 8px;
        flex: 1 1 calc(50% - 20px);
        margin: 5px 0;
        padding: 15px;
        width: auto;
        text-align: left;
        -webkit-box-shadow: 0px 0px 16px 0px rgba($color-4, 0.9);
        -moz-box-shadow: 0px 0px 16px 0px rgba($color-4, 0.9);
        box-shadow: 0px 0px 16px 0px rgba($color-4, 0.9);
    }

    .label{
        color: $color-primary;
        font-size: 1.7rem;
        font-weight: 500; 
    }

    .value{
        color: $color-primary;
        font-size: 2.2rem;
        font-weight: bold;
    }
}

@include media($min: $tablet){
    .stats{
        flex-wrap: nowrap;
        
        > div{
            flex: 1 1 calc(20% - 20px);
        }
    }
}

@include media($min: 1135px){
    .stats{
        > div{
            flex: 1 1 calc(20% - 20px);
            padding: 45px;
        }
    
        .label{
            color: $color-primary;
            font-size: 2.2rem;
            font-weight: 500;
        }
    
        .value{
            font-size: 3.2rem;
        }
    }
}