/* class selectors */

.layout-wrapper {background-color: $color-white; font-size: 1.8rem;}
.layout-content-wrapper {display: flex; flex-direction: column; justify-content: space-between; min-height: calc(100vh);}

.layout-content-outer {flex: 1 1 0/*; min-height: 600px;*/}
.layout-content-inner {background: transparent; height: 103%; padding-bottom: 50px;}
.layout-content-inner.gradient {background: linear-gradient(45deg, #364A56, #4E6B81); color: $color-white;}

.layout-header .wrapper,
.layout-content-outer .wrapper,
.layout-footer .wrapper {max-width: 1408px; margin: 0 auto; padding: 0 30px; height: 100%;}