.button-style{
    @extend %button-style;
}

a.button-style{
    display: inline-block;
    font-size: 1.4rem;
    margin-right: 10px;
    padding: 10px 15px;
}

.p-button {
    .layout-wrapper &, .layout-wrapper p-button &{
        background: $color-primary; 
        border-radius: 6px;
        border-color: $color-primary;
        font-size: 1.6rem;
        line-height: 1.2em;
        padding: 15px 20px;
        text-align: center;
        white-space: break-spaces;
        color: #fff;
        
        @include media($min: $tablet){
            font-size: 2rem;
        }
    }
    
    &:enabled{
        &:active,
        &:hover{
            background: $color-secondary;
            border-color: $color-7;
        }
    }

    &:focus{
        box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $color-8, 0 1px 2px 0 $color-black;
    }
}

p-button{
    body &{
        border: none;
        padding: 0;
        text-align: left;
    }

    + p-button{
        margin-left: 10px;
    }
}

.block-links{
    @extend %listbox;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;

    li{
        display: flex;
        flex: 1 1 33.3333%;

        a{
            background-color: $color-secondary;
            border-radius: 8px;
            color: $color-black;
            display: block;
            line-height: 1.3em;
            margin: 10px;
            min-height: 100px;
            padding: 20px;
            width: 100%;
            transition: background-color 0.25s ease, color 0.25s ease;

            &:hover{
                background-color: $color-primary;
                color: $color-white;
            }
        }
    }
}