@mixin media($max: false, $min: false) {
    @if $max {
        @if $min {
            @media screen and (max-width: $max) and (min-width: #{$min + 1}) { @content; }
        }@else{
            @media screen and (max-width: $max) { @content; }
        }
    }@else{
        @media screen and (min-width: #{$min + 1}) { @content; }
    }
}

@mixin placeholder{
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        @content;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        @content;
    }
    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }
}

@mixin before {
    &:before{
        @content;
    }

    &::before {
        @content;
    }
}

@mixin after {
    &:after{
        @content;
    }

    &::after {
        @content;
    }
}

@mixin before-after {
    @include before {
        @content;
    }
    @include after {
        @content;
    }
}