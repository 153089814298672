body table,
.dark-theme table {
    &.tbl-distribution-list{
        tbody{
            tr{
                td{
                    &[data-heading-4],
                    &[data-heading-5],
                    &[data-heading-6],
                    &[data-heading-7]{
                        flex: 0 1 50%;
                    }

                    @include media($min: $size-sm){
                        &[data-heading-3],
                        &[data-heading-4],
                        &[data-heading-5],
                        &[data-heading-6],
                        &[data-heading-7],
                        &[data-heading-8]{
                            flex: 0 1 33.333%;
                        }
                    }
                }
            }
        }
    }

    &.tbl-distribution-participant-list{
        tbody{
            tr{
                td{
                    &[data-heading-1]{
                        @include before{
                            display: inline-block;
                        }

                        @include media($min: $size-lg-2){
                            @include before{
                                display: none;
                            }
                        }
                    }

                    &[data-heading-2],
                    &[data-heading-3],
                    &[data-heading-6],
                    &[data-heading-7]{
                        flex: 1 1 50%;
                    }

                    @include media($min: 450px){
                        &[data-heading-6],
                        &[data-heading-7],
                        &[data-heading-8]{
                            flex: 0 1 33.333%;
                        }
                    }

                    @include media($min: $tablet-landscape){
                        &[data-heading-4],
                        &[data-heading-5]{
                            flex: 1 1 50%;
                        }   
                    }
                }
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"],
        textarea,
        .field-placeholder,
        input.p-inputtext{
            border-color: $color-6 !important;
            color: $color-2;
            font-size: 1.4rem;
            min-height: 0;

            @include placeholder{
                color: $color-2;
                font-size: 1.4rem;
            }

            &[readonly=""],
            &.read-only{
                background-color: transparent;
                padding: 0;

                @include media($min: $size-lg-2){
                    &:hover{
                        + .display-txt{
                            display: block;
                        }
                    }
                }
            }
        }

        .form-field{
            position: relative;
        }

        .display-txt{
            background-color: $color-white;
            border-radius: 3px;
            display: none;
            position: absolute;
            left: 0;
            padding: 5px;
            font-size: 1.4rem;
            line-height: 1em;
            z-index: 1;
        }
    }

    &.tbl-licenses{
        tbody{
            tr{
                td{
                    &[data-heading-1],
                    &[data-heading-2],
                    &[data-heading-4],
                    &[data-heading-5],
                    &[data-heading-6],
                    &[data-heading-7]{
                        flex: 1 1 50%;
                    }
    
                    &[data-heading-4],
                    &[data-heading-5],
                    &[data-heading-6],
                    &[data-heading-7]{
                        @media only screen and (min-width: 560px){
                            flex: 1 1 25%;
                        }
                    }
                }
            }
        }
    }

    &.tbl-list-dimension{
        thead{
            .updated-by{
                min-width: 150px;
            }
        }
    
        tbody{
            tr{
                td{
                    &[data-heading-3],
                    &[data-heading-4]{
                        flex: 1 1 50%;
                    }
                }
            }
        }
    }

    &.tbl-list-survey{
        tbody{
            tr{
                td{
                    &[data-heading-2],
                    &[data-heading-3]{
                        flex: 1 1 50%;
                    }
                }
            }
        }
    }

    &.tbl-list-theme{
        max-width: 650px;
    
        tbody{
            tr{
                td{
                    &[data-heading-1],
                    &[data-heading-2]{
                        flex: 1 1 50%;
                    }
                }
            }
        }
    }

    &.tbl-list-user{
        tbody{
            tr{
                td{
                    &[data-heading-1],
                    &[data-heading-2],
                    &[data-heading-4],
                    &[data-heading-5],
                    &[data-heading-6]{
                        flex: 1 1 50%;
    
                        @include media($min: $tablet){
                            flex: 1 1 33.3333%;
                        }
                    }
    
                    &[data-heading-3]{
                        @include media($min: $tablet){
                            flex: 1 1 33.3333%;
                        }
                    }
                }
            }
        }
    }

    &.tbl-list-question{
        tbody{
            td{
                td{
                    &[data-heading-5],
                    &[data-heading-6]{
                        flex: 1 1 50%;
                    }
                }
            }
        }
    }

    &.tbl-license-detail{
        tbody{
            tr{
                &.row-details{
                    td{ 
                        &[data-heading-3],
                        &[data-heading-4],
                        &[data-heading-5],
                        &[data-heading-6],
                        &[data-heading-7],
                        &[data-heading-8],
                        &[data-heading-9]{
                            flex: 1 1 50%;
                        }
    
                        &[data-heading-1],
                        &[data-heading-2]{
                            @include media($min: $size-sm){
                                flex: 1 1 50%;
                            }
                        }
    
                        &[data-heading-5],
                        &[data-heading-6],
                        &[data-heading-7],
                        &[data-heading-8]{
                            flex: 1 1 50%;
    
                            @include media($min: $size-sm){
                                flex: 1 1 25%;
                            }
    
                            @include media($min: $size-lg-2){
                                text-align: right;
                            }
                        }

                        p-button{
                            margin: 0;
                        }
                    }
                }

                &.row-participants{
                    td{
                        &[data-heading-1],
                        &[data-heading-2],
                        &[data-heading-5],
                        &[data-heading-6]{
                            flex: 1 1 50%;
                        }

                        &[data-heading-1],
                        &[data-heading-2],
                        &[data-heading-3]{
                            @include media($min: $size-sm){
                                flex: 1 1 33.333%;
                            }
                        }

                        table{
                            @include media($max: $size-lg-2){
                                border: none;
                            }

                            tr{
                                border-right: 1px solid $color-8;
                                border-left: 1px solid $color-8;

                                &:not(:first-child){
                                    border-top: 2px solid $color-8;
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.tbl-list-dimension{
        .reminder-date,
        .reminder-sent{
            flex: 1 1 auto;
        }

        .reminder-sent{
            font-weight: bold;
        }

        td{
            .edit{
                display: inline-block;
            }
        }
    }
}
