/* You can add global styles to this file, and also import other style files */
@import "assets/styles/settings",
"assets/styles/partials/general",
"assets/styles/partials/layout",
"assets/styles/partials/header",
"assets/styles/partials/footer",
"assets/styles/partials/buttons",
"assets/styles/partials/dashboard",
"assets/styles/partials/prime-elements",
"assets/styles/blocks/form-dark",
"assets/styles/blocks/form-light",
"assets/styles/blocks/stats",
"assets/styles/blocks/table",
"assets/styles/blocks/table-specific",
"assets/styles/blocks/participant-page";

html {
  font-size: 10px;
  font-family: $font-sans-serif;
}

body {
  font-family: $font-sans-serif;
  font-size: 1.8rem;
}

.spacer {
  flex: 1;
}

/* Main content */
.layout-content-outer {
  .content {
    display: flex;
    margin: 82px auto 32px;
    padding: 0 16px;
    max-width: 960px;
    flex-direction: column;
    align-items: center;
  }

  section {
    position: relative;
    border-radius: 20px;
    padding: 20px 0;
    color: #fff;
  }

  .search-filters{
    padding-right: 20px;
    padding-left: 20px;
  }

  /* Forms */
  fieldset.label {
    width: 25%;
  }

  fieldset.input,
  fieldset.textarea {
    width: 75%;
  }

  /* Statistics boxes */
  .stats {
    text-align: center;

  }

  .stats .label,
  .stats .value {
    display: block;
  }

  .stats .label {
    color: gray;
  }
}

/* Footer */

td,
th {
  padding: 5px;
}

input:read-only {
  color: gray;
  border: none;
  background-color: lightgrey;
}

.validation-error,
.form-input-error {
  border: 3px solid $color-secondary;
  border-radius: 8px;
  color: $color-secondary;
  line-height: 1.2em;
  margin: 5px 0;
  padding: 10px 20px;
  font-size: 1.8rem;
  transition: margin 0.25s ease;

  +.form-input-error {
    margin-top: 20px;
  }
}

.app-notice {
  color: darkblue;
  background-color: lightblue;
}

/*.status-incomplete{background-color: #92b2c9;}
.status-pending{background-color: #b6d6ee;}
.status-sent{background-color: #c8dfef;}
.status-complete{background-color: #e7f3fc;}*/


/* start new styles - Lance Hesketh - July 12, 2022 */

/* element selectors */
body {
  min-width: 100%;
  min-height: 100%;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}



.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem #BCCAD5;
  border-color: #42647E;
}

.p-inputtext:enabled:hover {
  border-color: $color-secondary;
}

/* language toggle buttons */
.localization-control>div {
  position: relative;
}

.localization-control .language-toggle {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin: -0.3em 0 0;
  padding: 0;
  top: 0;
  list-style-type: none;
  line-height: 10px;
}

.localization-control .language-toggle li {
  float: right;
  padding: 0;
  margin: 0 0 0 0.25rem;
}

.localization-control .language-toggle li button {
  border: none;
  font-size: 10px;
  line-height: 14.5px;
  padding: 2px 5px;
  height: 14.5px;
  overflow: hidden;
  color: #fff;
  margin: 0 !important;
}

.localization-control .language-toggle li button:before {
  content: "\e90b";
  font-family: primeicons;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 2px;
  font-size: 8px;
}

.localization-control .language-toggle li button[disabled] {
  color: #fff;
}

.localization-control .language-toggle li button.has-value:before {
  content: "\e909";
}

.localization-control .language-toggle li button.invalid {
  background-color: #f00;
}

/** Nav Menus **/
.nav-item {
  .nav-link {
    &.dropdown-toggle {
      position: relative;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 2px;
        width: 10px;
        height: 10px;
        transform: translateY(-50%) rotate(45deg);
        border-top: 2px solid $color-secondary;
        border-right: 2px solid $color-secondary;
        @media only screen and (min-width: 769px) {
          display: none;
        }
      }
    }
  }
}

/** Note that unlike bootstrap, these are large-down */
.d-md-block {
  display: block !important;
}
.d-md-none {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .d-none {
    display: none !important;
  }
  .d-block {
    display: block !important;
  }
  .dashboard-navigation-wrapper {
    .nav-tabs {
      overflow: hidden;
      .nav-item {
        &.dropdown {
          position: static;
          .dropdown-menu {
            display: block !important;
            transform: translateX(100%);
            top: 0;
            height: 100%;
            padding-top: 70px;
            box-shadow: none;
            border-radius: 0;
            transition: transform ease-in-out 0.25s;
          }
          &.open {
            .dropdown-toggle {
              position: static !important;
              &::before {
                content: attr(data-back-label);
                position: absolute;
                top: 20px;
                width: calc(100% - 40px);
                z-index: 100;
                display: block;
                z-index: 100;
                color: #4E6B81 !important;
              }
              &::after {
                transform: rotate(-135deg);
                left: 22px;
                z-index: 100;
                top: 29px;
                cursor: pointer;
              }
            }
            .dropdown-menu {
              transform: translateX(0);
            }
          }
        }
      }
    }   
  }
}