.layout-footer{
    background: $color-black;
    padding: 100px 0 50px;
    color: $color-white;
    font-size: 1.8rem;
    text-align: center;

    a{
        color: $color-white;

        &:hover{
            color: $color-secondary;
        }
    }

    section{
        border: none;
        border-radius: 0;
        margin: 0 0 4rem;
        padding: 0;

        p{
            line-height: 25px;

            &.header {
                line-height: 50px;
            }
        }
    }

    .logo {
        background-image: url('/assets/logo-footer.png');
    }

    .footer-nav{
        ul{
            list-style-type: none;
            line-height: 50px;
            margin: 30px 0 20px 0;
            padding: 0;

            li{
                list-style-type: none;
                margin: 0;
                padding: 0;

                a{
                    display: block;
                    margin: 0;
                    padding: 0;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .social-links{
        display: flex;
        justify-content: center;
        margin: 0 0 30px 0;

        .link-item{
            margin: 0 10px;
        }

        a {
            font-size: 3rem;
            line-height: 50px;

            &:hover{
                color: $color-secondary;
            }
        }
    }

    .copyright{
        font-size: 1.4rem;
    }
}


//.layout-footer .social div {width: 100%; text-align: right; margin-bottom: 1rem;}
//.layout-footer .social 

@include media($min: $size-lg){
    .layout-footer{
        text-align: left;

        .footer-nav{
            ul{
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .social-links{
            display: block;
            text-align: right;
        }

        .copyright{
            font-size: 2rem;
            padding: 30px 0 0 0;
        }
    }
}